import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './assets/styles/my.scss'

Vue.config.productionTip = false

import Element from 'element-ui'
import './assets/styles/element-variables.scss'
console.log(Element)
Vue.use(Element, {
  size: 'small' // set element-ui default size
})
// 引入svg相关文件
import './utils/icons.js'

import permission from './directive/permission/index.js'
Vue.use(permission)

import ep_table_column from "./views/components/ep_table_column"; //共同方法

import ep_color from "./views/components/ep_color";
import ep_select from "./views/components/ep_select";
import ep_user from "./views/components/ep_user";
import ep_date from "./views/components/ep_date";
import ep_select_value_label from "./views/components/ep_select_value_label";
import ep_col from "./views/components/ep_col";
import ep_rate from "./views/components/ep_rate";

Vue.component('ep_table_column', ep_table_column);
Vue.component('ep_color', ep_color);
Vue.component('ep_select', ep_select);
Vue.component('ep_user', ep_user);
Vue.component('ep_date', ep_date);
Vue.component('ep_select_value_label', ep_select_value_label);
Vue.component('ep_col', ep_col);
Vue.component('ep_rate', ep_rate);

import request from "./utils/request";
Vue.prototype.$http = request;

import base from './utils/base.js'
Vue.use(base)

Vue.config.productionTip = false

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
