import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: (resolve) => require(['@/views/login'], resolve),
            hidden: true
        },
        {
            path: '/',
            component: (resolve) => require(['@/views/Index'], resolve),
            hidden: true,
            children: [
                {
                    path: '/home',
                    component: (resolve) => require(['@/views/home'], resolve),
                    name: 'home',
                },
                {
                    path: '/add_sea',
                    component: (resolve) => require(['@/views/add_sea'], resolve),
                    name: 'add_sea',
                },
                {
                    path: '/list_sea',
                    component: (resolve) => require(['@/views/list_sea'], resolve),
                    name: 'list_sea',
                },
                {
                    path: '/detail_customer',
                    component: (resolve) => require(['@/views/detail_customer'], resolve),
                    name: 'detail_customer',
                },
                {
                    path: '/list_customer',
                    component: (resolve) => require(['@/views/list_customer'], resolve),
                    name: 'list_customer',
                },
                {
                    path: '/update_customer',
                    component: (resolve) => require(['@/views/update_customer'], resolve),
                    name: 'update_customer',
                },
                {
                    path: '/update_pay',
                    component: (resolve) => require(['@/views/update_pay'], resolve),
                    name: 'update_pay',
                },
                {
                    path: '/dashboard',
                    component: (resolve) => require(['@/views/dashboard'], resolve),
                    name: 'dashboard',
                },
                {
                    path: '/calendar',
                    component: (resolve) => require(['@/views/calendar'], resolve),
                    name: 'calendar',
                },
                {
                    path: '/to_do_list',
                    component: (resolve) => require(['@/views/to_do_list'], resolve),
                    name: 'to_do_list',
                },
                /*体验总览*/
                {
                    path: '/list_experience',
                    component: (resolve) => require(['@/views/list_experience'], resolve),
                    name: 'list_experience',
                },
                /*我的报备*/
                {
                    path: '/list_my_customer',
                    component: (resolve) => require(['@/views/list_my_customer'], resolve),
                    name: 'list_my_customer',
                },
                /*活动列表*/
                {
                    path: '/list_activity',
                    component: (resolve) => require(['@/views/list_activity'], resolve),
                    name: 'list_activity',
                },
                {
                    path: '/detail_activity',
                    component: (resolve) => require(['@/views/detail_activity'], resolve),
                    name: 'detail_activity',
                },
                {
                    path: '/list_msg',
                    component: (resolve) => require(['@/views/list_msg'], resolve),
                    name: 'list_msg',
                },
                {
                    path: '/detail_timeline_follow',
                    component: (resolve) => require(['@/views/detail_timeline_follow'], resolve),
                    name: 'detail_timeline_follow',
                },
                {
                    path: '/detail_timeline_visit',
                    component: (resolve) => require(['@/views/detail_timeline_visit'], resolve),
                    name: 'detail_timeline_visit',
                },
                {
                    path: '/chart_top',
                    component: (resolve) => require(['@/views/chart_top'], resolve),
                    name: 'chart_top',
                },
                {
                    path: '/count_sale_today_month',
                    component: (resolve) => require(['@/views/count_sale_today_month'], resolve),
                    name: 'count_sale_today_month',
                },
                {
                    path: '/list_my_class',
                    component: (resolve) => require(['@/views/list_my_class'], resolve),
                    name: 'list_my_class',
                },
                {
                    path: '/detail_class_visit',
                    component: (resolve) => require(['@/views/detail_class_visit'], resolve),
                    name: 'detail_class_visit',
                },
                {
                    path: '/list_student_attendance',
                    component: (resolve) => require(['@/views/list_student_attendance'], resolve),
                    name: 'list_student_attendance',
                },
                {
                    path: '/detail_student_attendance',
                    component: (resolve) => require(['@/views/detail_student_attendance'], resolve),
                    name: 'detail_student_attendance',
                },
                {
                    path: '/list_course',
                    component: (resolve) => require(['@/views/list_course'], resolve),
                    name: 'list_course',
                },
                {
                    path: '/list_change_class',
                    component: (resolve) => require(['@/views/list_change_class'], resolve),
                    name: 'list_change_class',
                },
                {
                    path: '/list_course_hour_summary',
                    component: (resolve) => require(['@/views/list_course_hour_summary'], resolve),
                    name: 'list_course_hour_summary',
                },
                {
                    path: '/list_class_comment',
                    component: (resolve) => require(['@/views/list_class_comment'], resolve),
                    name: 'list_class_comment',
                },
                {
                    path: '/class_comment',
                    component: (resolve) => require(['@/views/class_comment'], resolve),
                    name: 'class_comment',
                },
                {
                    path: '/detail_class_comment',
                    component: (resolve) => require(['@/views/detail_class_comment'], resolve),
                    name: 'detail_class_comment',
                },
                {
                    path: '/list_class_homework',
                    component: (resolve) => require(['@/views/list_class_homework'], resolve),
                    name: 'list_class_homework',
                },
                {
                    path: '/add_class_homework',
                    component: (resolve) => require(['@/views/add_class_homework'], resolve),
                    name: 'add_class_homework',
                },
                {
                    path: '/detail_class_homework',
                    component: (resolve) => require(['@/views/detail_class_homework'], resolve),
                    name: 'detail_class_homework',
                },
                {
                    path: '/reply_class_homework',
                    component: (resolve) => require(['@/views/reply_class_homework'], resolve),
                    name: 'reply_class_homework',
                },
            ]
        }
    ]
})
